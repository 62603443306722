import "core-js/stable";
import "regenerator-runtime/runtime";

import gsap from "gsap";

const articleMeta = document.querySelector('.article--meta');
const articleTitle = document.querySelector('.article--meta h1');
const articleImage = document.querySelector('.article--image img');

const metaRect = articleMeta?.getBoundingClientRect();
const titleRect = articleTitle?.getBoundingClientRect();

const computedSize = (element, attr) => {
    return parseFloat(getComputedStyle(element)[attr].split('px')[0])
};


document.addEventListener('scroll', () => {
    const imageRect = articleImage?.getBoundingClientRect();

    if (articleMeta && articleTitle && articleImage) {
        if (imageRect.top <= metaRect.top + metaRect.height) {
            const titleOpacity = 100 - ((titleRect.top + titleRect.height - imageRect.top) * 100 / imageRect.height);

            if (titleOpacity <= 100 && titleOpacity > 0) {
                gsap.to(articleTitle, .3, {
                    opacity: (titleOpacity * .01)
                })
            }
        }

        if (imageRect.top <= 0) {
            const imageOpacity = 100 + (imageRect.top * 100 / titleRect.height);

            if (imageOpacity <= 100) {
                gsap.to(articleImage, .3, {
                    opacity: imageOpacity * .01,
                    //y: -(100 - imageOpacity)
                })
            }
        }
        if (imageRect.top > titleRect.top) {
            gsap.to([articleImage, articleTitle], .3, {
                opacity: 1,
            })
        }
    }
});

